import { Injectable } from '@angular/core';
import { JWT } from './jwt';

@Injectable({
  providedIn: 'root'
})
export class TokenStore {
  setAccessToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  getAccessToken(): JWT {
    const token = localStorage.getItem('accessToken');

    if(!token)
      return null;
    
    return new JWT(token);
  }

  removeAccessToken() {
    localStorage.removeItem('accessToken');
  }

  setRefreshToken(token: string) {
    localStorage.setItem('refreshToken', token);
  }

  getRefreshToken(): string {
    return localStorage.getItem('refreshToken');
  }

  removeRefreshToken() {
    localStorage.removeItem('refreshToken');
  }
}