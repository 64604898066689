<div class="card" [class.no-border]="noBorder">
  <div class="card-body m-b-t-50">
    <div class="row center">
      <div class="col-md-12">
        <img src="assets/credit_card.png" class="img-responsive m-b-30" height="30" alt="">
      </div>
    </div>
    <div class="row center" *ngIf="title">
      <div class="col-md-12 m-b-10">
        <label class="title">
          {{ title | translate }}
        </label>
      </div>
    </div>
    <div class="row center" *ngIf="message">
      <div class="col-md-12">
        <label class="message">
          {{ message | translate }}
        </label>
      </div>
    </div>
  </div>
</div>