import { APIException } from './api-exception';
import { ValidationErrors } from './validation-errors';

export class APIValidationException extends APIException {
    errors: ValidationErrors;

    constructor(errors: ValidationErrors) {
        super('Validation error');
        this.errors = errors;
    }
}