import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { UserService } from 'src/app/services/user.service';
import { InvalidCredentialsError } from 'src/app/security/invalid-credentials-error';
import { AccountDisabledError } from 'src/app/security/account_disabled_error';
import { Router } from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  busy = false;
  isInvalid = false;
  isAccountDisabled = false;
  showPassword = false;

  constructor(
    private builder: FormBuilder,
    private userSvc: UserService,
    private router: Router,
    ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.builder.group({
      username: ['', [
        Validators.required,
      ]],
      password: ['', [
        Validators.required,
      ]],
    });
  }

  async login() {
    try {
      this.isInvalid = false;
      this.busy = true;
      const jwt = await this.userSvc.login(this.form.value.username, this.form.value.password);
      this.router.navigateByUrl('/');
    } catch (err) {
      if (err) {
        this.isInvalid = err instanceof InvalidCredentialsError
        this.isAccountDisabled = err instanceof AccountDisabledError
      } else {
        this.router.navigateByUrl('/500');
      }
    } finally {
      this.busy = false;
    }
  }

  showHide() {
    this.showPassword = !this.showPassword;
  }

}
