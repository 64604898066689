import { ValidationError } from './validation-error';

export class ValidationErrors {
  errors = new Map<string, ValidationError>();

  constructor(errors: ValidationError[]) {
    errors.forEach((err) => {
      this.errors.set(err.source, err);
    });
  }

  has(source: string, error: string): boolean {
    const value: ValidationError = this.errors.get(source);

    return value != null && value.errors.includes(error);
  }

  clear() {
    this.errors.clear();
  }
}