import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MerchantUser } from '../models/merchant-user';
import { UserService } from 'src/app/services/user.service';
import { ToolbarService } from 'src/app/services/toolbar.service';
import { Role } from '../security/role';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss']
})
export class BaseComponent implements OnInit {
  showSidebar = true;
  showMobileSidebar = false;
  currentUser: MerchantUser;
  Role = Role;
  toolbarSubscription: Subscription;
  
  constructor(
    private router: Router,
    private userSvc: UserService,
    private toolbarSvc: ToolbarService,
  ) {
    this.toolbarSubscription = this.toolbarSvc.shouldRefresh().subscribe((data) => {
      if (data) {
        this.getMe();
        this.toolbarSvc.setRefresh(false);
      }
    });
   }

  ngOnDestroy() {
    this.toolbarSubscription.unsubscribe();
  }
  ngOnInit(): void {
    this.getMe();
  }

  async getMe() {
    try {
      this.currentUser = await this.userSvc.getMe({
        path: `
          id
          username
          firstName
          middleName
          lastName
          mobile
          isChangePassRequired
        `
      });
      
      if (this.currentUser.isChangePassRequired)
        this.router.navigateByUrl('/set-password');
    } catch (err) {
      this.router.navigateByUrl('/500');
    }
  }

  async logout() {
    try {
      await this.userSvc.logout();
      this.router.navigateByUrl('login');
    } catch (err) {
      this.router.navigateByUrl('/500');
    }
  }

  async profile() {
    try {
      this.router.navigateByUrl('profile');
    } catch (err) {
      this.router.navigateByUrl('/500');
    }
  }

  async helpCenter() {
    try {
      this.router.navigateByUrl('help-center');
    } catch (err) {
      this.router.navigateByUrl('/500');
    }
  }

  get activeRoute(): string {
    const url = this.router.url;
    if (url.startsWith('/dashboard')) return '/dashboard';
    if (url.startsWith('/transactions')) return '/transactions';
    if (url.startsWith('/branches')) return '/branches';
    if (url.startsWith('/users/admins')) return '/users/admins';
    if (url.startsWith('/users/managers')) return '/users/managers';
    if (url.startsWith('/users/frontliners')) return '/users/frontliners';
    if (url.startsWith('/reports')) return '/reports';
    if (url.startsWith('/terms')) return '/terms';
    if (url.startsWith('/help-center')) return '/help-center';
    return '';
  }

  toggleSidebar() {
    this.showSidebar = !this.showSidebar;
    const t = window.dispatchEvent(new Event('resize'));
    
    if (this.showSidebar)
      document.getElementById('burger').style.maxWidth = '250px';
    else
      document.getElementById('burger').style.maxWidth = '70px';
  }
}
