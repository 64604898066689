<div class="row form login-bg">
	<div class="col-md-4 offset-md-4">
		<div class="text-center">
			<img src="assets/Venio_Logo_White_Transparent_BG.png" class="img-responsive m-b-20" height="70" alt="" draggable="false"><br>
			<label class="m-label">{{ 'l.venio.merchant' | translate }}</label> 
		</div>
		<div class="login-border">
			<form id="form:setPassword" [formGroup]="form">
				<div class="form-group text-center m-b-20">
          <h6 class="f-w-500">{{ 'l.set.password.below' | translate }}</h6><br>
          <p class="f-s-12 text-left" [innerHTML]="'l.password.details' | translate"></p>
				</div>
        <div class="form-group">
					<div class="inner-addon right-addon">
            <span class="fa {{ showPassword ? 'fa-eye' : 'fa-eye-slash' }}" (click)="showPassword = !showPassword"></span>
            <input id ="field:password" type="{{ showPassword ? 'text' : 'password' }}" placeholder="{{ 'l.password' | translate }}" class="form-control" formControlName="password">
          </div>
          <span 
            class="error-label" 
            *ngIf="password.hasError('pattern') || errors != null && errors.has('password', 'password_strength')">
            {{ 'm.did.not.meet.requirements' | translate }}
          </span>
          <span class="error-label" *ngIf="errors != null && errors.has('password', 'minimum')">
            {{ 'm.error.min' | translate}}
          </span>
				</div>
				<div class="form-group">
					<div class="inner-addon right-addon">
					<span class="fa {{ showConfirmPassword ? 'fa-eye' : 'fa-eye-slash' }}" (click)="showConfirmPassword = !showConfirmPassword"></span>
					<input id ="field:confirmPassword" type="{{ showConfirmPassword ? 'text' : 'password' }}" placeholder="{{ 'l.confirm.password' | translate }}" class="form-control" formControlName="confirmPassword">
          </div>
          <span 
            class="error-label" 
            *ngIf="confirmPassword.value && !passwordsMatched()">
            {{ 'm.invalid.confirm.password' | translate }}
          </span>
        </div>
				<div class="form-group m-t-20">
					<button id ="button:setPassword" class="btn btn-primary btn-block active" [disabled]="form.invalid || busy || !passwordsMatched()" (click)="setPassword()">{{ 'l.set.password' | translate }}</button>
				</div>
			</form>
		</div>
	</div>
</div>
