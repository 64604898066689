import { NgModule } from '@angular/core';
import { Config } from './config';

export function provideConfig() {
  return Config.instance;
}

@NgModule({
  providers: [
    {
      provide: Config,
      useFactory: provideConfig
    }
  ]
})
export class ConfigModule{}