import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { BaseRoutingModule } from './base-routing.module';
import { BaseComponent } from './base.component';
import { TranslateModule } from '@ngx-translate/core';
import { ComponentsModule } from '../components/components.module';
import { NotFoundComponent } from 'src/app/pages/errors/not-found/not-found.component';
import { ServerErrorComponent } from 'src/app/pages/errors/server-error/server-error.component';
import { UnauthorizedComponent } from 'src/app/pages/errors/unauthorized/unauthorized.component';
import { ForbiddenComponent } from 'src/app/pages/errors/forbidden/forbidden.component';

@NgModule({
  declarations: [
    BaseComponent,
    NotFoundComponent, 
    ServerErrorComponent, 
    UnauthorizedComponent, 
    ForbiddenComponent, 
  ],
  imports: [
    CommonModule,
    BaseRoutingModule,
    TranslateModule.forChild(),
    ComponentsModule,
  ]
})
export class BaseModule { }
