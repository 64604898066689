import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ValidationErrors } from 'src/app/api/validation-errors';
import { passwordRegex } from 'src/app/models/user';
import { AccountDisabledError } from 'src/app/security/account_disabled_error';
import { InvalidCredentialsError } from 'src/app/security/invalid-credentials-error';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-set-password',
  templateUrl: './set-password.component.html',
  styleUrls: ['./set-password.component.scss']
})
export class SetPasswordComponent implements OnInit {
  form: FormGroup;
  errors: ValidationErrors;
  busy = false;
  isInvalid = false;
  isAccountDisabled = false;
  showPassword = false;
  showConfirmPassword = false;

  constructor(
    private builder: FormBuilder,
    private userSvc: UserService,
    private router: Router,
  ) { }

  ngOnInit(): void {
    this.buildForm();
  }

  buildForm() {
    this.form = this.builder.group({
      password: ['', [
        Validators.required,
        Validators.pattern(passwordRegex)
      ]],
      confirmPassword: ['', [
        Validators.required,
      ]],
    });
  }

  async setPassword() {
    try {
      this.isInvalid = false;
      this.busy = true;
      await this.userSvc.setPassword({ password: this.password.value });
      this.router.navigate(['/'], { replaceUrl: true });
    } catch (err) {
      this.router.navigateByUrl('/500');
    } finally {
      this.busy = false;
    }
  }

  passwordsMatched(): boolean {
    return this.password.value === this.confirmPassword.value;
  }

  get password(): AbstractControl {
    return this.form.get('password');
  }

  get confirmPassword(): AbstractControl {
    return this.form.get('confirmPassword');
  }
}
