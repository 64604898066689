import { APINotFoundException } from './api-not-found-exception';
import { APIServerException } from './api-server-exception';
import { APIValidationException } from './api-validation-exception';
import { ValidationError } from './validation-error';
import { ValidationErrors } from './validation-errors';

export abstract class API {
  handle(result: any) {
    if (result.errors == null || result.errors.length == 0) return;

    const error = result.errors[0];

    switch (error.message) {
      case 'validation_error':
        const ext = error.extensions as Map<string, string[]>;
        const errors = Object.keys(ext).map((k) => {
            return new ValidationError(k, ext[k]);
        });
        throw new APIValidationException(
            new ValidationErrors(errors),
        );
      case 'record_not_found':
        throw new APINotFoundException();
      default:
        throw new APIServerException(error.message);
    }
  }
}