export class Role {
  name: string;

  public static DASHBOARD_VIEW_BALANCE = 'dashboard:view_balance';
  public static TRANSACTION_LIST_DEPOSITS = 'transaction:list_deposits';
  public static TRANSACTION_LIST_PAYMENTS = 'transaction:list_payments';
  public static TRANSACTION_REPORTS = 'transaction:reports';
  public static TRANSACTION_SEARCH_DEPOSITS = 'transaction:search_deposits';
  public static TRANSACTION_SEARCH_PAYMENTS = 'transaction:search_payments';
  public static TRANSACTION_VIEW = 'transaction:view';
  public static BRANCH_LIST = 'branch:list';
  public static BRANCH_SEARCH = 'branch:search';
  public static BRANCH_CREATE = 'branch:create';
  public static BRANCH_VIEW = 'branch:view';
  public static BRANCH_UPDATE = 'branch:update';
  public static USER_LIST = 'user:list';
  public static USER_CREATE = 'user:create';
  public static USER_VIEW = 'user:view';
  public static USER_UPDATE = 'user:update';
  public static USER_DELETE = 'user:delete';
}
