import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[resize]'
})
export class ResizeDirective {
  resizeSubscription: Subscription;
  currentWidth: number;
  readonly width: number = 1024;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
    this.viewContainer.createEmbeddedView(this.templateRef);
    this.currentWidth = window.innerWidth;
    this.resizeSubscription = fromEvent(window, 'resize').subscribe((evt: any) => {
      if (this.currentWidth > this.width || evt.target.screen.width !== this.currentWidth) {
        this.currentWidth = evt.target.screen.width;
        this.viewContainer.clear();
        setTimeout(() => this.viewContainer.createEmbeddedView(this.templateRef), 1);
      }
    });
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
}
