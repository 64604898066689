import { Injectable } from '@angular/core';
import { TokenStore } from './token-store';
import { KeycloakClient } from './keycloak-client';
import { JWT } from './jwt';
import { SessionExpiredError } from './session-expired-error';

@Injectable({
	providedIn: 'root'
})
export class Auth {
	constructor(
		private tokenStore: TokenStore,
		private keycloak: KeycloakClient,
	) { }

	async isLoggedIn(): Promise<boolean> {
    const jwt = this.tokenStore.getAccessToken();
		if (!jwt) return false;

		try {
			const json = JSON.parse(atob(jwt.access_token.split('.')[1]));
			const exp = json['exp'];

			if (new Date() >= new Date(exp * 1000)) {
				const jwt = await this.refreshToken();

				return jwt !== null;
			}

			return true;
		} catch (err) {
			return false;
		}
	}

	async login(username: string, password: string): Promise<JWT> {
		const jwt = await this.keycloak.login(username, password);
		this.tokenStore.setAccessToken(jwt.access_token);
    this.tokenStore.setRefreshToken(jwt.refresh_token);

		return jwt;
	}

	async logout(): Promise<void> {
		await this.keycloak.logout();
		this.tokenStore.removeAccessToken();
    this.tokenStore.removeRefreshToken();
    // TODO: Remove other objects from storage
	}

	async refreshToken(): Promise<JWT> {
		const token = this.tokenStore.getRefreshToken();
		const json = JSON.parse(atob(token.split('.')[1]));
		const exp = json['exp'];

		if (new Date() >= new Date(exp * 1000)) {
			this.tokenStore.removeAccessToken();
			this.tokenStore.removeRefreshToken();

			throw new SessionExpiredError();
		}

		const jwt = await this.keycloak.refreshToken(token);
		this.tokenStore.setAccessToken(jwt.access_token);
		this.tokenStore.setRefreshToken(jwt.refresh_token);

		return jwt;
	}
}