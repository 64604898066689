import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';




@Injectable({
  providedIn: 'root'
})
export class ToolbarService {
  private _shouldRefresh: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() { }

  shouldRefresh(): Observable<boolean> {
    return this._shouldRefresh.asObservable();
  }

  setRefresh(r: boolean) {
    this._shouldRefresh.next(r);
  }
}
