import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { ResizeDirective } from './resize/resize.directive';
import { HasRoleDirective } from './has-role/has-role.directive';
import { AmountComponent } from './amount/amount.component';
import { EmptyTableComponent } from './empty-table/empty-table.component';
import { TranslateModule } from '@ngx-translate/core';
import { IsDesktopDirective } from './is-desktop/is-desktop.directive';
import { IsMobileDirective } from './is-mobile/is-mobile.directive';


@NgModule({
  declarations: [ResizeDirective, HasRoleDirective, AmountComponent, EmptyTableComponent, IsDesktopDirective, IsMobileDirective],
  imports: [
    CommonModule,
    NgxDatatableModule,
    TranslateModule.forChild(),
  ],
  exports: [
    AmountComponent,
    ResizeDirective,
    HasRoleDirective,
    EmptyTableComponent,
    IsDesktopDirective,
    IsMobileDirective,
  ],
})
export class ComponentsModule { }
