import { Model } from './model';

export const emailRegex = '^(([^<>()\\[\\]\\\\.,;:\\s@\"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@\"]+)*)|(\".+\"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$';
export const passwordRegex = '^((?!.*[\\s])(?=.+[a-zA-Z])(?=.+[\\d]).{8,255})$';

export interface User extends Model {
  mobile: string;
  firstName: string;
  middleName: string;
  lastName: string;
  email: string;
  enabled: boolean;
  isChangePassRequired: boolean;
}