import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injector, APP_INITIALIZER } from '@angular/core';

import { Config } from './config/config';
import { AppRoutingModule } from './app-routing.module';
import { ConfigModule } from './config/config.module';
import { GraphQLModule } from './api/graphql.module';
import { AppComponent } from './app.component';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BaseModule } from './pages/base.module';
import { LoginModule } from './pages/login/login.module';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

export function initialize(http: HttpClient) {
  return () => {
    return http.get<Config>('/config.json')
      .toPromise()
      .then(Config.initialize);
  }
};

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    // Custom modules
    ConfigModule,
    GraphQLModule,
    BaseModule,
    LoginModule,
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initialize,
    multi: true,
    deps: [HttpClient]
  }],
  bootstrap: [AppComponent]
})
export class AppModule {
  static injector: Injector;
  config: any;

  constructor(injector: Injector) {
    AppModule.injector = injector;
  }
}
