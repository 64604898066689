<!-- Start of Desktop view layout -->
<ng-container *isDesktop>
  <div class="row m-0">
    <div class="col-md-10 p-l-0">
      <div class="navbar navbar-expand-lg navbar-light logo-width" id="burger">
        <div class="navbar-brand">
          <button type="button" class="btn btn-link btn-aln" (click)="toggleSidebar()">
            <span class="navbar-toggler-icon"></span>
          </button>
          <a href="/dashboard" *ngIf="showSidebar">
            <img src="assets/logo.png" class="img-responsive logo-aln" height="30" alt="">
          </a>

          <ul class="navbar-nav mr-auto">
            <li class="nav-item dropdown">
              
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="col-md-2 text-right">
      <ul class="nav navbar-nav navbar-right">
        <li class="nav-item dropdown">
          <div class="btn-group m-0" *ngIf="currentUser">
            <button type="button" class="btn btn-secondary dropdown-toggle username m-t-r m-r-0" data-toggle="dropdown" aria-haspopup="true"
              aria-expanded="false">
                {{ currentUser.firstName }} {{ currentUser.lastName }}
            </button>
            <div class="dropdown-menu dropdown-menu-right">
                <div class="modal-size">
                <label class="lbl-name">{{ currentUser.firstName }} {{ currentUser.lastName }}</label><br>
                <label class="lbl-mobile">{{ currentUser.mobile }}</label>
                </div>
              <hr/>
                <button class="dropdown-item btn-width" type="button" (click)="profile()">{{ 'l.profile' | translate  }}</button>
                <button class="dropdown-item btn-width" type="button" (click)="helpCenter()">{{ 'l.help.center' | translate  }}</button>
              <hr/>
              <button class="dropdown-item lbl-color btn-width" type="button" (click)="logout()">{{ 'l.log.out' | translate  }}</button>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
 
  <div class="row content m-l-r-0">
    <div 
      class="p-l-r-0 sidebar" 
      [class.sidebar-show]="showSidebar" 
      [class.sidebar-hide]="!showSidebar">
      <div class="list-group p-0 m-t-2">
        
        <ng-container>
          <a 
            id="sidebar:dashboard"
            *ngIf="showSidebar"
            class="list-group-item" 
            [routerLink]="['/dashboard']" 
            [class.active]="activeRoute === '/dashboard'">
            <img 
              [src]="activeRoute === '/dashboard' ? 'assets/dashboard_red.png' : 'assets/dashboard.png'" 
              class="img-responsive" 
              width="30"
              height="30">
            <label>{{ 'l.dashboard' | translate }}</label>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/dashboard']" 
            [class.active]="activeRoute === '/dashboard'">
            <img 
              [src]="activeRoute === '/dashboard' ?  'assets/dashboard_red.png' : 'assets/dashboard.png'" 
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
  
        <ng-container *hasRole="'';multiple:[Role.TRANSACTION_LIST_DEPOSITS, Role.TRANSACTION_LIST_PAYMENTS]">
          <a 
            id="sidebar:transactions"
            *ngIf="showSidebar"
            class="list-group-item" 
            [routerLink]="['/transactions']" 
            [class.active]="activeRoute === '/transactions'">
            <img 
              [src]="activeRoute === '/transactions' ?  'assets/transactions_red.png' : 'assets/transactions.png'"
              class="img-responsive" 
              width="30"
              height="30">
            <label>{{ 'l.transactions' | translate }}</label>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/transactions']"
            [class.active]="activeRoute === '/transactions'">
            <img 
              [src]="activeRoute === '/transactions' ?  'assets/transactions_red.png' : 'assets/transactions.png'"
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
  
        <ng-container *hasRole="Role.TRANSACTION_REPORTS">
          <a 
            id="sidebar:reports"
            *ngIf="showSidebar"
            class="list-group-item" 
            [routerLink]="['/reports']" 
            [class.active]="activeRoute === '/reports'">
            <img 
              [src]="activeRoute === '/reports' ? 'assets/reports_red.png' : 'assets/reports.png'" 
              class="img-responsive" 
              width="30"
              height="30">
            <label>{{ 'l.reports' | translate }}</label>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/reports']" 
            [class.active]="activeRoute === '/reports'">
            <img 
              [src]="activeRoute === '/reports' ?  'assets/reports_red.png' : 'assets/reports.png'" 
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
  
        <ng-container *hasRole="Role.BRANCH_LIST">
          <a 
            id="sidebar:branches"
            *ngIf="showSidebar"
            class="list-group-item" 
            [routerLink]="['/branches']" 
            [class.active]="activeRoute === '/branches'">
            <img 
              [src]="activeRoute === '/branches' ?  'assets/branches_red.png' : 'assets/branches.png'"
              class="img-responsive" 
              width="30"
              height="30">
            <label>{{ 'l.branches' | translate }}</label>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/branches']"
            [class.active]="activeRoute === '/branches'">
            <img 
              [src]="activeRoute === '/branches' ?  'assets/branches_red.png' : 'assets/branches.png'"
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
  
        <ng-container *hasRole="Role.USER_LIST">
          <a 
            id="sidebar:users"
            *ngIf="showSidebar"
            class="list-group-item" 
            data-toggle="collapse" 
            href="#users-collapse"
            aria-expanded="false" 
            aria-controls="users-collapse">
            <img 
              src="assets/users.png" 
              class="img-responsive" 
              width="30"
              height="30">
            <label class="m-r-5-e">{{ 'l.users' | translate }}</label>
            <i class="fa fa-1 fa-chevron-right light pull-right"></i>
            <i class="fa fa-1 fa-chevron-down pull-right"></i>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/users/admins']">
            <img 
              src="assets/users.png" 
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
  
        <div class="collapse" id="users-collapse" *ngIf="showSidebar">
          <a class="list-group-item" 
            id="users:admins"
            [routerLink]="['/users/admins']" 
            [class.active]="activeRoute === '/users/admins'"
            *hasRole="Role.BRANCH_LIST">
            <span class="subitem">{{ 'l.admins' | translate}}</span>
          </a>
          <a class="list-group-item" 
            id="users:managers"
            [routerLink]="['/users/managers']" 
            [class.active]="activeRoute === '/users/managers'"
            *hasRole="Role.BRANCH_LIST">
            <span class="subitem">{{ 'l.managers' | translate}}</span>
          </a>
          <a class="list-group-item" 
            id="users:frontliners"
            [routerLink]="['/users/frontliners']" 
            [class.active]="activeRoute === '/users/frontliners'"
            *hasRole="Role.BRANCH_LIST">
            <span class="subitem">{{ 'l.frontliners' | translate}}</span>
          </a>
        </div>
  
        <ng-container>
          <a 
            id="sidebar:helpCenter"
            *ngIf="showSidebar"
            class="list-group-item" 
            [routerLink]="['/help-center']" 
            [class.active]="activeRoute === '/help-center'">
            <img 
              [src]="activeRoute === '/help-center' ?  'assets/help_center_red.png' : 'assets/help_center.png'"
              class="img-responsive" 
              width="30"
              height="30">
            <label>{{ 'l.help.center' | translate }}</label>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/help-center']"
            [class.active]="activeRoute === '/help-center'">
            <img 
              [src]="activeRoute === '/help-center' ?  'assets/help_center_red.png' : 'assets/help_center.png'"
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
  
        <ng-container>
          <a 
            id="sidebar:terms"
            *ngIf="showSidebar"
            class="list-group-item" 
            [routerLink]="['/terms']" 
            [class.active]="activeRoute === '/terms'">
            <img 
              [src]="activeRoute === '/terms' ?  'assets/terms_red.png' : 'assets/terms.png'"
              class="img-responsive" 
              width="30"
              height="30">
            <label>{{ 'l.terms' | translate }}</label>
          </a>
          <a 
            *ngIf="!showSidebar"
            class="list-group-item" 
            [routerLink]="['/terms']"
            [class.active]="activeRoute === '/terms'">
            <img 
              [src]="activeRoute === '/terms' ?  'assets/terms_red.png' : 'assets/terms.png'"
              class="img-responsive m-r-0" 
              width="30"
              height="30">
          </a>
        </ng-container>
      </div>    
    </div>
    <div class="body-2" 
      [class.sidebar-show]="showSidebar" 
      [class.sidebar-hide]="!showSidebar">
      <div class="m-25">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</ng-container>
<!-- End of Desktop view layout -->

<!-- Start of Mobile view layout -->
<ng-container *isMobile>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="navbar-brand">
      <button type="button" class="btn btn-link" style="margin-right: 5vw;" (click)="showMobileSidebar = !showMobileSidebar">
        <span class="navbar-toggler-icon"></span>
      </button>
      <a href="/dashboard">
        <img src="assets/logo.png" class="img-responsive" height="30" alt="">
      </a>
    </div>
    
    <ul class="navbar-nav mr-auto">
      <li class="nav-item dropdown">
        
      </li>
    </ul>
    <ul class="nav navbar-nav navbar-right">
      <li class="nav-item dropdown">
        <div class="btn-group m-0" *ngIf="currentUser">
          <button type="button" class="btn btn-secondary dropdown-toggle username" data-toggle="dropdown" aria-haspopup="true"
            aria-expanded="false">
             {{ currentUser.firstName }} {{ currentUser.lastName }}
          </button>
          <div class="dropdown-menu dropdown-menu-right">
             <div class="modal-size">
              <label class="lbl-name">{{ currentUser.firstName }} {{ currentUser.lastName }}</label><br>
              <label class="lbl-mobile">{{ currentUser.mobile }}</label>
             </div>
            <hr/>
              <button class="dropdown-item btn-width" type="button" (click)="profile()">{{ 'l.profile' | translate  }}</button>
              <button class="dropdown-item btn-width" type="button" (click)="helpCenter()">{{ 'l.help.center' | translate  }}</button>
            <hr/>
            <button class="dropdown-item lbl-color btn-width" type="button" (click)="logout()">{{ 'l.log.out' | translate  }}</button>
          </div>
        </div>
      </li>
    </ul>
  </nav>
  <div class="overlay" (click)="showMobileSidebar = false" *ngIf="showMobileSidebar"></div>
  <div class="mob-sidebar" 
    [class.active]="showMobileSidebar"
    [class.inactive]="!showMobileSidebar">
    <div class="list-group p-0 m-t-2">
      <ng-container>
        <a
          class="list-group-item" 
          [routerLink]="['/dashboard']" 
          [class.active]="activeRoute === '/dashboard'"
          (click)="showMobileSidebar = false">
          <img 
            [src]="activeRoute === '/dashboard' ? 'assets/dashboard_red.png' : 'assets/dashboard.png'" 
            class="img-responsive" 
            width="30"
            height="30">
          <label>{{ 'l.dashboard' | translate }}</label>
        </a>
      </ng-container>

      <ng-container *hasRole="'';multiple:[Role.TRANSACTION_LIST_DEPOSITS, Role.TRANSACTION_LIST_PAYMENTS]">
        <a 
          class="list-group-item" 
          [routerLink]="['/transactions']" 
          [class.active]="activeRoute === '/transactions'"
          (click)="showMobileSidebar = false">
          <img 
            [src]="activeRoute === '/transactions' ?  'assets/transactions_red.png' : 'assets/transactions.png'"
            class="img-responsive" 
            width="30"
            height="30">
          <label>{{ 'l.transactions' | translate }}</label>
        </a>
      </ng-container>

      <ng-container *hasRole="Role.TRANSACTION_REPORTS">
        <a 
          class="list-group-item" 
          [routerLink]="['/reports']" 
          [class.active]="activeRoute === '/reports'"
          (click)="showMobileSidebar = false">
          <img 
            [src]="activeRoute === '/reports' ? 'assets/reports_red.png' : 'assets/reports.png'" 
            class="img-responsive" 
            width="30"
            height="30">
          <label>{{ 'l.reports' | translate }}</label>
        </a>
      </ng-container>

      <ng-container *hasRole="Role.BRANCH_LIST">
        <a 
          class="list-group-item" 
          [routerLink]="['/branches']" 
          [class.active]="activeRoute === '/branches'"
          (click)="showMobileSidebar = false">
          <img 
            [src]="activeRoute === '/branches' ?  'assets/branches_red.png' : 'assets/branches.png'"
            class="img-responsive" 
            width="30"
            height="30">
          <label>{{ 'l.branches' | translate }}</label>
        </a>
      </ng-container>

      <ng-container *hasRole="Role.USER_LIST">
        <div style="width: 100%;">
          <a 
            class="list-group-item" 
            data-toggle="collapse" 
            href="#users-collapse"
            aria-expanded="false" 
            aria-controls="users-collapse">
            <img 
              src="assets/users.png" 
              class="img-responsive" 
              width="30"
              height="30">
            <label style="width: 70%;">{{ 'l.users' | translate }}</label>
            <i class="fa fa-1 fa-chevron-right light pull-right"></i>
            <i class="fa fa-1 fa-chevron-down pull-right"></i>
          </a>
        </div>
      </ng-container>

      <div class="collapse" id="users-collapse" *ngIf="showSidebar">
        <a class="list-group-item" 
          [routerLink]="['/users/admins']" 
          [class.active]="activeRoute === '/users/admins'"
          *hasRole="Role.BRANCH_LIST"
          (click)="showMobileSidebar = false">
          <span class="subitem">{{ 'l.admins' | translate}}</span>
        </a>
        <a class="list-group-item" 
          [routerLink]="['/users/managers']" 
          [class.active]="activeRoute === '/users/managers'"
          *hasRole="Role.BRANCH_LIST"
          (click)="showMobileSidebar = false">
          <span class="subitem">{{ 'l.managers' | translate}}</span>
        </a>
        <a class="list-group-item" 
          [routerLink]="['/users/frontliners']" 
          [class.active]="activeRoute === '/users/frontliners'"
          *hasRole="Role.BRANCH_LIST"
          (click)="showMobileSidebar = false">
          <span class="subitem">{{ 'l.frontliners' | translate}}</span>
        </a>
      </div>

      <ng-container>
        <a 
          class="list-group-item" 
          [routerLink]="['/help-center']" 
          [class.active]="activeRoute === '/help-center'"
          (click)="showMobileSidebar = false">
          <img 
            [src]="activeRoute === '/help-center' ?  'assets/help_center_red.png' : 'assets/help_center.png'"
            class="img-responsive" 
            width="30"
            height="30">
          <label>{{ 'l.help.center' | translate }}</label>
        </a>
      </ng-container>

      <ng-container>
        <a 
          class="list-group-item" 
          [routerLink]="['/terms']" 
          [class.active]="activeRoute === '/terms'"
          (click)="showMobileSidebar = false">
          <img 
            [src]="activeRoute === '/terms' ?  'assets/terms_red.png' : 'assets/terms.png'"
            class="img-responsive" 
            width="30"
            height="30">
          <label>{{ 'l.terms' | translate }}</label>
        </a>
      </ng-container>
    </div>
  </div>
  <div class="mob-content"
    [class.s-active]="showMobileSidebar"
    [class.s-inactive]="!showMobileSidebar">
    <div class="m-25">
      <router-outlet></router-outlet>
    </div>
  </div>
</ng-container>
<!-- End of Mobile view layout -->