import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-amount',
  templateUrl: './amount.component.html',
  styleUrls: ['./amount.component.scss']
})
export class AmountComponent implements OnInit {
  @Input() symbol: string;
  @Input() amount: number;
  @Input() locale: string = 'en-US';
  displayAmount: string;

  constructor() { }

  ngOnInit(): void {
    this.displayAmount = `${this.symbol} ${this.amount.toLocaleString(this.locale, {minimumFractionDigits: 2, maximumFractionDigits: 2})}`
  }
}
