import { Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[isMobile]'
})
export class IsMobileDirective {
  readonly width: number = 1024;
  resizeSubscription: Subscription;
  injected = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef) {
    this.checkWidth();
    this.resizeSubscription = fromEvent(window, 'resize').subscribe(evt => {
      this.checkWidth();
    });
  }

  checkWidth() {
    if (window.innerWidth <= this.width && !this.injected) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.injected = true;
    }
    else if (window.innerWidth > this.width) {
      this.viewContainer.clear();
      this.injected = false;
    }
  }

  ngOnDestroy() {
    this.resizeSubscription.unsubscribe();
  }
}
