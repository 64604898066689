import { Injectable } from '@angular/core';
import { Auth } from '../security/auth';
import { JWT } from '../security/jwt';
import { GQLOpts } from '../api/gql-opts';
import { MerchantUser } from '../models/merchant-user';
import { UsersApi } from '../api/users-api';
import { ListUsersByStore } from '../queries/list-users-by-store';
import { ListUsers } from '../queries/list-users';
import { APIList } from '../models/api-list';
import { CreateMerchantUser } from '../commands/create-merchant-user';
import { UpdateMerchantUser } from '../commands/update-merchant-user';
import { UpdateUser } from '../queries/update-user';
import { ChangePassword } from '../queries/change-password';
import { MerchantStore } from '../models/merchant-store';
import { SearchMerchantUser } from '../queries/search-merchant-user';
import { SetPassword } from '../queries/set-password';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private auth: Auth,
    private usersApi: UsersApi,
  ) { }

  async isLoggedIn(): Promise<boolean> {
    return this.auth.isLoggedIn();
  }

  async logout(): Promise<void> {
    return this.auth.logout();
  }

  async getMe(opts: GQLOpts): Promise<MerchantUser> {
    return this.usersApi.getMe(opts);
  }

  async login(username: string, password: string): Promise<JWT> {
    return this.auth.login(username, password);
  }

  async listUsersByStore(query: ListUsersByStore, opts: GQLOpts): Promise<APIList<MerchantUser>> {
    return this.usersApi.listUsersByStore(query, opts);
  }

  async listUsers(query: ListUsers, opts: GQLOpts): Promise<APIList<MerchantUser>> {
    return this.usersApi.listUsers(query, opts);
  } 

  async createUser(cmd: CreateMerchantUser, opts: GQLOpts): Promise<MerchantUser> {
    return this.usersApi.createUser(cmd, opts);
  }

  async updateUser(cmd: UpdateMerchantUser): Promise<boolean> {
    return this.usersApi.updateUser(cmd);
  }

  async getUser(id: string, opts: GQLOpts): Promise<MerchantUser> {
    return this.usersApi.getUser(id, opts);
  }
  async updateMe(cmd: UpdateUser): Promise<boolean> {
    return this.usersApi.updateMe(cmd);
  }

  async changePassword(cmd: ChangePassword): Promise<boolean> {
    return this.usersApi.changePassword(cmd);
  }

  async getMerchantStore(id: string, opts: GQLOpts): Promise<MerchantStore> {
    return this.usersApi.getMerchantStore(id, opts);
  }

  async searchMerchantUser(query: SearchMerchantUser, opts: GQLOpts): Promise<APIList<MerchantUser>> {
    return this.usersApi.searchMerchantUser(query, opts);
  }

  async setPassword(cmd: SetPassword): Promise<boolean> {
    return this.usersApi.setPassword(cmd);
  }
}
