import { NgModule } from '@angular/core';
import { HttpClientModule, HttpHeaders } from '@angular/common/http';
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { ApolloLink } from 'apollo-link';
import { Config } from '../config/config';
import { TokenStore } from '../security/token-store';

export function provideApollo(config: Config, httpLink: HttpLink, tokenStore: TokenStore) {
  const auth = new ApolloLink((operation, forward) => {
    const jwt = tokenStore.getAccessToken();
    if(jwt) {
      operation.setContext({
        headers: new HttpHeaders({
          'Authorization': `Bearer ${jwt.access_token}`
        })
      });
    }
    return forward(operation);
  });
  const links = [
    auth,
    httpLink.create({uri: `${config.api.baseUrl}/merchant/query`})
  ];
  return {
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      mutate: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      }
    }
  };
}

@NgModule({
  exports: [
    HttpClientModule,
    ApolloModule,
    HttpLinkModule
  ],
  providers: [{
    provide: APOLLO_OPTIONS,
    useFactory: provideApollo,
    deps: [Config, HttpLink, TokenStore]
  }]
})
export class GraphQLModule {}