import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Injectable({ providedIn: 'root' })
export class BaseResolver implements Resolve<Promise<any>> {
  constructor(
    private titleSvc: Title,
    private translate: TranslateService,
  ) {}

  async resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<any> {
    const title = await this.translate.get(route.data.title).toPromise();

    this.titleSvc.setTitle(title);

    return title;
  }
}