import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { TokenStore } from 'src/app/security/token-store';

@Directive({
  selector: '[hasRole]'
})
export class HasRoleDirective {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private tokenStore: TokenStore,
  ) { }

  @Input() set hasRole(role: string) {
    const jwt = this.tokenStore.getAccessToken();
    if (!role || !jwt) return;
    if (role && jwt.hasRole(role)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  @Input() set hasRoleMultiple(roles: string[]) {
    const jwt = this.tokenStore.getAccessToken();
    if (!roles || roles.length === 0 || !jwt) return;

    const userRoles = jwt.getRoles();
    const r = userRoles.filter(item => roles.indexOf(item) < 0);
    
    if (r.length != userRoles.length) this.viewContainer.createEmbeddedView(this.templateRef);
    else this.viewContainer.clear();
  }
}
