export class JWT {
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  scope: string;

  constructor(token) {
    this.access_token = token;
  }

  getRoles(): string[] {
    const json = JSON.parse(atob(this.access_token.split('.')[1]));

    return json.realm_access ? json.realm_access.roles : null;
  }

  hasRole(role: string): boolean {
    const roles = this.getRoles();

    return role 
      && roles 
      && roles.includes(role);
  }
}