<div class="row form login-bg m-l-0">
	<div class="col-md-4 offset-md-4">
		<div class="text-center">
			<img src="assets/Venio_Logo_White_Transparent_BG.png" class="img-responsive m-b-20" height="70" alt="" draggable="false"><br>
			<label class="m-label">{{ 'l.venio.merchant' | translate }}</label> 
		</div>
		<div class="login-border">
			<form id="form:login" [formGroup]="form">
				<div class="form-group text-center m-b-20">
					{{ 'l.sign.in' | translate }}
				</div>
				<div class="form-group">
					<input id ="field:username" type="text" class="form-control" placeholder="{{ 'l.mobile' | translate }}" formControlName="username">
				</div>
				<div class="form-group">
					<div class="inner-addon right-addon">
					<span class="fa {{ showPassword ? 'fa-eye' : 'fa-eye-slash' }}" (click)="showHide()"></span>
					<input id ="field:password" type="{{ showPassword ? 'text' : 'password' }}" placeholder="{{ 'l.password' | translate }}" class="form-control" formControlName="password">
					</div>
				</div>
				<div class="form-group">
					<div class="checkbox">
						<input type="checkbox" class="m-r-5" value=""> {{ 'l.remember' | translate }}
					</div>
				</div>
				<div class="form-group" *ngIf="isAccountDisabled">
					<label id="error:disabledAccount" class="error-label">{{ 'm.account.disabled' | translate }}</label>
				</div>
				<div class="form-group" *ngIf="isInvalid">
					<label id="error:invalidCredentials" class="error-label">{{ 'm.credentials.invalid' | translate }}</label>
				</div>
				<div class="form-group m-t-60">
					<button id ="button:login" class="btn btn-primary btn-block active" [disabled]="form.invalid || busy" (click)="login()">{{ 'l.log.in' | translate }}</button>
				</div>
			</form>
		</div>
	</div>
</div>
