import { Injectable } from '@angular/core';
import { API } from './api';
import { Apollo } from 'apollo-angular';
import { GQLOpts } from './gql-opts';
import { MerchantUser } from '../models/merchant-user';
import gql from 'graphql-tag';
import { APIList } from '../models/api-list';
import { ListUsersByStore } from '../queries/list-users-by-store';
import { CreateMerchantUser } from '../commands/create-merchant-user';
import { UpdateMerchantUser } from '../commands/update-merchant-user';
import { UpdateUser } from '../queries/update-user';
import { ChangePassword } from '../queries/change-password';
import { MerchantStore } from '../models/merchant-store';
import { ListUsers } from '../queries/list-users';
import { SearchMerchantUser } from '../queries/search-merchant-user';
import { SetPassword } from '../queries/set-password';

@Injectable({
	providedIn: 'root'
})
export class UsersApi extends API {
	constructor(
		private apollo: Apollo
	) {
		super();
	}

	async getMe(opts: GQLOpts): Promise<MerchantUser> {
		const query = gql`
			query getMe {
				getMe {
					${opts.path}
				}
			}
		`;

		const result: any = await this.apollo
			.query({
				query
			}).toPromise();

		this.handle(result);

		return result.data.getMe as MerchantUser;
	}
	
	async updateMe(cmd: UpdateUser): Promise<boolean> {
		const mutation = gql`
			mutation updateMe 
			(
				$input: UpdateUser!
			) {
				updateMe(input: $input)
			}
		`;

	const result: any = await this.apollo
		.mutate({
			variables: {
				input: {
					firstName: cmd.firstName,
					middleName: cmd.middleName,
					lastName: cmd.lastName,
				}
			},
			mutation
		}).toPromise();

		this.handle(result);

		return result.data.updateMe as boolean;
	}
	
	async changePassword(cmd: ChangePassword): Promise<boolean> {
    const mutation = gql`
      mutation changePassword(
        $input: ChangePassword!
      ) {
        changePassword(input: $input)
      }
    `;

    const result: any = await this.apollo
      .mutate({
        variables: {
          input: {
            currentPassword: cmd.currentPassword,
            newPassword: cmd.newPassword
          }
        },
        mutation
      }).toPromise();

    this.handle(result);


    return result.data.changePassword as boolean;
  }

  async listUsersByStore(query: ListUsersByStore, opts: GQLOpts): Promise<APIList<MerchantUser>> {
		const q = gql`
			query listUsersByStore(
        $input: ListUsersByStore!
			) {
				listUsersByStore(input: $input) {
          ${opts.path}
        }
			}
    `;
    
		const r: any = await this.apollo
			.query({
				variables: {
          input: {
            max: query.opts.max,
            offset: query.opts.offset,
            sort: query.opts.sort,
            order: query.opts.order,
          },
        },
				query: q
			}).toPromise();

		this.handle(r);
		return r.data.listUsersByStore as APIList<MerchantUser>;
	}
	
	async listUsers(query: ListUsers, opts: GQLOpts): Promise<APIList<MerchantUser>> {
   const q = gql`
     query listUsers(
         $input: ListUsers!
        ) {
        listUsers(input: $input) {
          ${opts.path}
        }
      }
    `;
    
    const r: any = await this.apollo
      .query({
        variables: {
          input: {
            max: query.opts.max,
            offset: query.opts.offset,
            sort: query.opts.sort,
            order: query.opts.order,
            capabilities: query.capabilities,
          },
        },
        query: q
     }).toPromise();

    this.handle(r);
    return r.data.listUsers as APIList<MerchantUser>;
  }

  async createUser(cmd: CreateMerchantUser, opts: GQLOpts): Promise<MerchantUser>  {
    const params: any = { 
      firstName: cmd.firstName,
      lastName: cmd.lastName,
      mobile: cmd.mobile,
      capabilities: cmd.capabilities,
      enabled: !cmd.enabled,
    };

    if(cmd.email)
      params.email = cmd.email;

    const mutation = gql`
			mutation createMerchantUser(
				$input: CreateMerchantUser!
			) {
				createMerchantUser(input: $input) {
					${opts.path}
				}
			}
		`;

		const r: any = await this.apollo
			.mutate({
				variables: {
					input: params
				},
				mutation
			}).toPromise();

		this.handle(r);
		return r.data.createMerchantUser as MerchantUser;
  }

  async updateUser(cmd: UpdateMerchantUser): Promise<boolean>  {
    const params: any = { 
      id: cmd.id,
      firstName: cmd.firstName,
      lastName: cmd.lastName,
      mobile: cmd.mobile,
      capabilities: cmd.capabilities,
      enabled: !cmd.enabled,
    };

    if(cmd.email)
      params.email = cmd.email;

    const mutation = gql`
			mutation updateMerchantUser(
				$input: UpdateMerchantUser!
			) {
				updateMerchantUser(input: $input)
			}
		`;

		const r: any = await this.apollo
			.mutate({
				variables: {
					input: params
				},
				mutation
			}).toPromise();

		this.handle(r);
		return r.data.updateMerchantUser as boolean;
  }

  async getUser(id: string, opts: GQLOpts): Promise<MerchantUser> {
		const query = gql`
			query getMerchantUser($id: ID!) {
				getMerchantUser(id: $id) {
          ${opts.path}
        }
			}
		`;

		const r: any = await this.apollo
			.query({
				variables: {
					id
				},
				query
      }).toPromise();

		this.handle(r);
		return r.data.getMerchantUser as MerchantUser;
	}
	
	async getMerchantStore(id: string, opts: GQLOpts): Promise<MerchantStore> {
		const query = gql`
			query getMerchantStore($id: ID!) {
				getMerchantStore(id: $id) {
					${opts.path}
				}
			}
		`;

		const r: any = await this.apollo
			.query({
				variables: {
					id
				},
				query
      }).toPromise();

		this.handle(r);
		return r.data.getMerchantStore as MerchantStore;
  }

  async searchMerchantUser(query: SearchMerchantUser, opts: GQLOpts): Promise<APIList<MerchantUser>> {
  	const q = gql`
      query searchMerchantUser($input: SearchMerchantUser!) {
        searchMerchantUser(input: $input) {
          ${opts.path}
        }
      }
    `;

	  const result: any = await this.apollo
      .query({
        variables: {
          input: {
            keywords: query.keywords,
            max: query.max,
            offset: query.offset,
            merchantId: query.merchantId,
            capabilities: query.capabilities,
          }
        },
        query: q
      }).toPromise();

    this.handle(result);

    return result.data.searchMerchantUser as APIList<MerchantUser>;
	}
	
	async setPassword(cmd: SetPassword): Promise<boolean> {
    const mutation = gql`
      mutation setPassword(
        $input: SetPassword!
      ) {
        setPassword(input: $input)
      }
		`;

		const result: any = await this.apollo
      .mutate({
        variables: {
          input: {
            password: cmd.password,
          }
        },
        mutation
      }).toPromise();

    this.handle(result);

    return result.data.setPassword as boolean;
  }
}