import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JWT } from './jwt';
import { Config } from '../config/config';
import { InvalidCredentialsError } from './invalid-credentials-error';
import { APIServerException } from '../api/api-server-exception';
import { TokenStore } from './token-store';
import { AccountDisabledError } from './account_disabled_error';

@Injectable({
  providedIn: 'root'
})
export class KeycloakClient {
  constructor(
    private http: HttpClient, 
    private config: Config,
    private tokenStore: TokenStore,
  ) {}

  async login(username: string, password: string): Promise<JWT> {
    let body = new URLSearchParams();
    body.set('grant_type', 'password');
    body.set('client_id', this.config.keycloak.clientId);
    body.set('username', username);
    body.set('password', password);
    const url = `${this.config.api.baseUrl}/auth/realms/${this.config.keycloak.realm}/protocol/openid-connect/token`;
    
    try {
      const jwt = await this.http.post<JWT>(url, body.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      }).toPromise();
  
      return jwt;
    } catch (err) {
      if (err.status == 401) {
        throw new InvalidCredentialsError();
      } else if (err.status == 400)  {
        throw new AccountDisabledError();
      }
      else {
        throw new APIServerException(err.message);
      }
    }
  }

  async logout(): Promise<void> {
    let body = new URLSearchParams();
    body.set('refresh_token', this.tokenStore.getRefreshToken());
    body.set('client_id', this.config.keycloak.clientId);
    const url = `${this.config.api.baseUrl}/auth/realms/${this.config.keycloak.realm}/protocol/openid-connect/logout`;
    
    try {
      await this.http.post(url, body.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      }).toPromise();
    } catch (err) {
      throw new APIServerException(err.message);
    }
  }

  async refreshToken(token: string): Promise<JWT> {
    let body = new URLSearchParams();
    body.set('grant_type', 'refresh_token');
    body.set('client_id', this.config.keycloak.clientId);
    body.set('refresh_token', token);
    const url = `${this.config.api.baseUrl}/auth/realms/${this.config.keycloak.realm}/protocol/openid-connect/token`;
    
    try {
      const jwt = await this.http.post<JWT>(url, body.toString(), {
        headers: new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' })
      }).toPromise();
  
      return jwt;
    } catch (err) {
      throw new APIServerException(err.message);
    }
  }
}