export class Config {
  static instance: Config;

  static initialize(config: any) {
    const instance = new Config();
    instance.api = config.api as API;
    instance.keycloak = config.keycloak as Keycloak;
    Config.instance = instance;
  }

  api: API;
  keycloak: Keycloak;
}

interface API {
  baseUrl: string;
}

interface Keycloak {
  clientId: string;
  realm: string;
}