import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { BaseComponent } from './base.component';
import { BaseResolver } from './base.resolver';
import { SetPasswordComponent } from '../pages/set-password/set-password.component';


const routes: Routes = [
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full'
      },
      {
        path: 'branches',
        loadChildren: () => import('./branches/branches.module').then(c => c.BranchesModule),
      },
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(c => c.DashboardModule),
      },
      {
        path: 'transactions',
        loadChildren: () => import('./transactions/transactions.module').then(c => c.TransactionsModule),
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(c => c.UsersModule),
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(c => c.ProfileModule),
      },
      {
        path: 'reports',
        loadChildren: () => import('./reports/reports.module').then(c => c.ReportsModule),
      },
      {
        path: 'help-center',
        loadChildren: () => import('./help-center/help-center.module').then(c => c.HelpCenterModule),
      },
      {
        path: 'terms',
        loadChildren: () => import('./terms/terms.module').then(c => c.TermsModule),
      },
    ],
    data: {
      title: 't.venio',
    },
    resolve: {
      resolver: BaseResolver,
    }
  },
  {
    path: '',
    component: SetPasswordComponent,
    children: [
      {
        path: 'set-password',
        loadChildren: () => import('./set-password/set-password.module').then(c => c.SetPasswordModule),
      },
    ],
    data: {
      title: 't.venio',
    },
    resolve: {
      resolver: BaseResolver,
    }
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BaseRoutingModule { }
