import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './security/auth.guard';
import { AnonymousGuard } from './security/anonymous.guard';
import { ForbiddenComponent } from './pages/errors/forbidden/forbidden.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ServerErrorComponent } from './pages/errors/server-error/server-error.component';
import { UnauthorizedComponent } from './pages/errors/unauthorized/unauthorized.component';
import { BaseResolver } from './pages/base.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule),
    canActivate: [AnonymousGuard]
  },
  {
    path: '',
    loadChildren: () => import('./pages/base.module').then(m => m.BaseModule),
    canActivate: [AuthGuard]
  },
  {
    path: '401', 
    component: UnauthorizedComponent,
    data: {
      title: 't.venio.401'
    },
    resolve: {
      resolver: BaseResolver,
    }
  },
  {
    path: '403', 
    component: ForbiddenComponent,
    data: {
      title: 't.venio.403'
    },
    resolve: {
      resolver: BaseResolver,
    }
  },
  {
    path: '500', 
    component: ServerErrorComponent,
    data: {
      title: 't.venio.500'
    },
    resolve: {
      resolver: BaseResolver,
    }
  },
  {
    path: '**', 
    component: NotFoundComponent,
    data: {
      title: 't.venio.404'
    },
    resolve: {
      resolver: BaseResolver,
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {onSameUrlNavigation: 'reload'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
